<template>
  <img :src="logoSrc.img" :width="logoSrc.width" height="56px" alt="HubbleStar Logo" />
</template>

<script>
export default {
  props: {
    source: {
      type: String,
      required: false
    }
  },
  computed: {
    logoSrc() {
      const userType = this.source ? this.source.toLowerCase() : this.$store.getters.userInfo?.userType?.toLowerCase();
      switch (userType) {
        case 'ntp':
          return {img: require(`@/assets/II-TED_logo.svg`), width: '68px' }
        default:
          return {img: require(`@/assets/logo.svg`),  width: '150px'}
      }
    }
  },

}
</script>
