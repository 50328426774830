<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="250"
      content-class="alert-dialog"
    >
      <v-card class="text-center pa-6">
        <!-- Success Icon -->
        <img
            class="mb-3"
            src="../assets/SuccessTick.svg"
            alt="Succes Tick"
            height="40px"
          />
  
        <!-- Dialog Title -->
        <h2  class="mb-2 lh-24 fw-500">
          Submitted
        </h2>
  
        <!-- Dialog Message -->
        <p class="text-subtitle-1 grey--text mb-6" >
          {{text}}
        </p>
      
  
        <!-- OK Button -->
        <div class="d-flex justify-center">
          <v-btn class="btn primary-btn" width="200" rounded large depressed @click="closeDialog">Ok
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'AlertDialogView',
    
    props: {
      value: {
        type: Boolean,
        default: true
      },
      text: {
        type: String,
        default: ''
      }
    },
    
    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    
    methods: {
      closeDialog() {
        this.dialog = false
        this.$emit('dialog-closed')
      }
    }
  }
  </script>
  
  <style scoped>
  .alert-dialog {
    border-radius: 8px;
  }
  .v-card {
    border-radius: 8px;
  }
  .lh-24{
    line-height: 24px;
  }
  .fw-500{
    font-weight: 500;
  }
  </style>