import axios from "axios";
import AuthService from '../services/AuthService'
import store from '../store';

const createAxiosInstance = (baseURL) => {
    const instance = axios.create({ baseURL });
  
    // Request Interceptor
    instance.interceptors.request.use(
      (config) => {
        const token = AuthService.getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  
    // Response Interceptor
    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401) {
          try {
            const currentRefreshToken = store.getters.refreshToken;
  
            const response = await AuthService.verifyRefreshToken(currentRefreshToken);
            const { accessToken } = response.data;
            // Update token in store
            store.dispatch("setToken", accessToken);
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return instance(originalRequest);
          } catch (refreshError) {
            AuthService.logout();
            alert("Authentication Failed");
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );
  
    return instance;
  };

  export default createAxiosInstance;
  