import axios from 'axios'
import AuthService from '../services/AuthService';
import createAxiosInstance from '../interceptor/axiosInterceptor';
const instance = createAxiosInstance(process.env.VUE_APP_USERS_API_URL);

export default {
    getUserInfo: async function () {
        const userId = AuthService.getUserId()
        console.log("userId*****", userId)
        try {
            const response = await instance.get('/getUserInfo?userId=' + userId)
            return response;
        } catch (error) {
            return error.response;
        }
    },


    publishResult: async function () {
        try {
            const userId = AuthService.getUserId()
            const response = await instance.get('/publishResult?userId=' + userId,)
            return response;
        } catch (error) {
            return error.response;
        }
    },

    getUserInfoFromResume: async function (
        path,
        fileType
    ) {
        try {
            const userId = AuthService.getUserId()
            const response = await instance.post(
                "/resume/parse",
                {
                    userId,
                    path,
                    fileType
                });
            return response;
        } catch (error) {
            console.log(error.data);
        }
    },

    saveAssessmentAction: async function (userId,campaignId,action) {
        try {
            const response = await instance.post(
                "/saveAssessmentAction",
                {
                    userId,
                    campaignId,
                    action
                });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    getHRDetails: async function(schoolCode) {
        try {
            const response = await instance.get("/getHRDetails?schoolCode="+schoolCode);
            return response.data;
        } catch (error) {
            console.error("Error",error);
        }
    }
}