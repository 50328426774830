<template>
    <v-container class="pa-0">
        <v-card class="pa-6">
            <h2 class="text-h6 mb-4">Raise a Query</h2>
            
                <BubbleLoader v-if="isLoading"/>
           
            <v-form ref="form" v-model="isValid">
                <!-- Name Fields -->
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field class="rounded-xl" v-model="formData.firstName" label="First Name*"
                            :rules="[v => !!v || 'First name is required']" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field class="rounded-xl" v-model="formData.lastName" label="Last Name*"
                            :rules="[v => !!v || 'Last name is required']" outlined hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>
                <!-- Contact Fields -->
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field class="rounded-xl" v-model="formData.email" label="Email Id*" :rules="emailRules"
                            outlined type="email" hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field class="rounded-xl" v-model="formData.phoneNumber" label="Mobile No*"
                            :rules="mobileRules" outlined type="tel" maxlength="10" hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="12">
                        <!-- Issue Title -->
                        <v-text-field v-model="formData.issueTitle" label="Issue Title*"
                            :rules="[v => !!v || 'Issue title is required']" outlined class="rounded-xl"
                            hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>

                <!-- Category Dropdown -->
                <v-row>
                    <v-col cols="12" md="12">
                        <v-select v-model="formData.issueCategory" :items="categories" label="Category*"
                            :rules="[v => !!v || 'Category is required']" outlined class="rounded-xl"
                            hide-details="auto" item-text="name" item-value="id" return-object
                            @change="handleCategorySelection"></v-select>
                    </v-col>
                </v-row>

                <v-row v-if="childCategories.length > 0">
                    <v-col v-for="category in childCategories" :key="category.id" cols="12" md="12">
                        <v-text-field class="rounded-xl" v-if="category.type === 'url'"
                            v-model="formData['childCategories'][category.key.replace(/\s+/g, '')]"
                            :label="`${category.name}*`"
                            :rules="[v => !!v || `${category.name} is required`, v => (category.type !== 'url' || (category.type === 'url' && urlPattern.test(v))) || 'Invalid URL']"
                            outlined hide-details="auto"></v-text-field>
                        <v-textarea class="rounded-xl" v-else-if="category.type === 'textarea'"
                            v-model="formData['childCategories'][category.key.replace(/\s+/g, '')]"
                            :label="`${category.name}*`"
                            :rules="[v => !!v || `${category.name} is required`]"
                            outlined hide-details="auto"></v-textarea>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="12">
                    <label class="font-16" for="issueDescription">Issue Description</label><sup>*</sup>
                    <!-- Hidden text field for validation -->
                    <v-text-field
                        v-model="formData.issueDescription"
                        :rules="descriptionRules"
                        style="display: none"
                    ></v-text-field>
                    
                    <ckeditor 
                        :config="editorConfig"
                        v-model="formData.issueDescription"
                        class="m-ckeditor"
                        :name="'ckeditor'"
                        @ready="onEditorReady"
                        id="issueDescription"
                    ></ckeditor>
                    </v-col>
                </v-row>
                <!-- Issue Description -->
                <v-row>
                    <v-col cols="12">
                        <p>Attach</p>
                        <div class="dotted-border" 
                            @dragover.prevent
                            @drop.prevent="handleFileDrop"
                            @dragleave.prevent>
                            <div class="pa-2 d-flex flex-column" v-if="issueAttachmentName == ''"
                                @click="$refs.fileInput.click()">
                                <img class="mx-auto" src="../assets/upload-resume.svg" alt="upload">
                                <div class="font-weight-light text-center" v-if="issueAttachmentName == ''">
                                    <p role-type="body-5" class="black--text">Drag and drop a file</p>
                                    <div class="d-none">
                                        browse file
                                        <input type="file" name="csv" ref="fileInput" @change="handleFileUpload"
                                            accept=".svg,.png,.jpg,.jpeg,.webp,.doc,.docx" />
                                    </div>
                                    <p role-type="body-6" class="grey--text">(image, pdf or word) (512 kb max.)</p>
                                </div>
                            </div>
                            <div class="pa-2 d-flex justify-center" v-else>
                                <template v-if="issueImagePreview === 'doc'">
                                    <v-icon color="black" class="mr-1" size="75">mdi-file-document-outline</v-icon>
                                </template>
                                <template v-else-if="issueImagePreview === 'pdf'">
                                    <v-icon color="black" class="mr-1" size="75">mdi-file-pdf-box</v-icon>
                                </template>
                                <img v-else :src="issueImagePreview" :alt="issueAttachmentName" height="40px"
                                    width="60px" class="ma-2" />

                                <p class="align-self-center">{{ issueAttachmentName }} <v-icon class="pointer"
                                        @click="clearImageFile">mdi-close</v-icon></p>

                            </div>
                        </div>
                    </v-col>
                </v-row>

                <!-- Action Buttons -->
                <v-row justify="end">
                    <v-col cols="auto">
                        <v-btn class="btn default-btn" rounded large @click="resetForm">
                            CANCEL
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn class="btn primary-btn" rounded large :disabled="!isValid" @click="submitForm()"
                            :loading="isSubmitting">Submit</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
import BubbleLoader from "../components/BubbleLoader.vue";
import QueryFormController from "../controllers/QueryFormController";
// import loadingGif from '@/assets/new_admin_Loader.gif';

export default {
    name: 'QueryFormView',
    components:{BubbleLoader},
    props: {
        firstName: {
            type: String,
            default: ''
        },
        lastName: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            default: ''
        },
        phoneNumber: {
            type: String,
            default: ''
        },
        userId: {
            type: String
        }
    },
    data() {
        return {
            isValid: false,
            // loadingGif,
            formData: {
                issueCategory: '',
                firstName: this.firstName, // Use prop if present
                lastName: this.lastName, // Use prop if present
                email: this.email, // Use prop if present
                phoneNumber: this.phoneNumber, // Use prop if present
                issueTitle: '',
                issueDescription: '',
                childCategories: {}
            },
            issueImagePreview: null,
            issueAttachmentName: '',
            issueAttachmentBlob: null,
            issueAttachmentType: '',
            editorConfig: {
                height: '100',
                toolbar: [
                    ['Bold', 'Italic', 'Underline', 'Strikethrough'],
                    ['Styles', 'Format', 'Font', 'FontSize'],
                    ['Undo', 'Redo'],
                ]
            },
            categories: [],
            childCategories: [],
            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid'
            ],
            mobileRules: [
                v => !!v || 'Mobile number is required',
                v => /^\d{10}$/.test(v) || 'Mobile number must be 10 digits'
            ],
            urlPattern: new RegExp("^(https?:\\/\\/)?([\\w\\-]+\\.)+[\\w\\-]+(\\/[\\w\\-._~:/?#[\\]@!$&'()*+,;=]*)?$"),
            isLoading: false,
            isSubmitting: false,
            descriptionRules: [
                v => !!v || 'Issue description is required',
            ]
        }

    },
    mounted() {
        this.getIssueCategories()
    },
    methods: {
        async getIssueCategories() {
            try {
                this.isLoading = true
                const response = await QueryFormController.getIssueCategories();
                this.categories = response.data.data; // Assuming the response contains the categories in the data property                
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                this.isLoading = false
            }
        },
        async getIssueCategory(categoryId) {
            try {
                this.isLoading = true
                const response = await QueryFormController.getIssueCategory(categoryId);
                this.childCategories = response.data?.childCategories;
                this.formData.childCategories = {}
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                this.isLoading = false
            }
        },
        resetForm() {
            this.formData.issueTitle = '',
            this.formData.issueDescription = '',
            this.formData.issueCategory = '';
            if(!this.userId) {
                this.$refs.form.reset()
            }
            this.clearImageFile()
            this.$emit('form-closed')
        },
        async submitForm() {
            if (this.$refs.form.validate()) {
                try {
                    this.isLoading = true
                    this.isSubmitting = true
                    // Add your API call here
                    console.log('Form submitted:', this.formData)
                    await QueryFormController.submitQuery({
                        "candidateName": `${this.formData.firstName} ${this.formData.lastName}`,
                        "email": this.formData.email,
                        "phoneNumber": this.formData.phoneNumber,
                        "issueCategory": this.formData.issueCategory.name,
                        "issueTitle": this.formData.issueTitle,
                        "issueDescription": this.formData.issueDescription,
                        "issueAttachment": this.issueAttachmentName ? [
                            {
                                content: this.issueAttachmentBlob, // Pass the data URL
                                filename: this.issueAttachmentName, // Name of the file in the email
                                type: this.issueAttachmentType // MIME type (optional, as it will be extracted from the data URL)
                            }
                        ] : [],
                        "hasAttachment": this.issueAttachmentName ? "Yes" : "No",
                        "userId": this.userId,
                        "childCategories": { ...this.formData.childCategories }
                    })
                    // Show success message
                    this.isLoading = false
                    this.isSubmitting = false
                    this.$emit('form-submitted', this.formData)
                    this.resetForm()
                } catch (error) {
                    console.error('Error submitting form:', error)
                }
            }
        },
        validateAndProcessFile(file) {
            const maxSize = 512 * 1024;
            const validTypes = ['image/svg+xml', 'image/png', 'image/jpeg', 'image/webp', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'];
            
            if (!validTypes.includes(file.type)) {
                alert('Please upload valid files');
                return false;
            }

            if (file.size > maxSize) {
                alert('File size should not exceed 512KB');
                return false;
            }

            this.createImagePreview(file);
            this.issueAttachmentName = file.name;
            this.issueAttachmentType = file.type;

            const reader = new FileReader();
            reader.onloadend = () => {
                this.issueAttachmentBlob = reader.result;
            };
            reader.readAsDataURL(file);
            return true;
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                if (!this.validateAndProcessFile(file)) {
                    event.target.value = ''; // Clear the input
                }
            }
        },
        handleFileDrop(event) {
            const file = event.dataTransfer.files[0];
            if (file) {
                this.validateAndProcessFile(file);
            }
        },

        createImagePreview(file) {
            const reader = new FileReader();
            if (file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                // Handle PDF and Word files
                this.issueImagePreview = 'doc'; // Clear image preview for non-image files
                // You can set a flag or handle the file differently if needed
            } else if (file.type === 'application/pdf') {
                this.issueImagePreview = 'pdf';
            } else {
                // Handle image files
                reader.onload = (e) => {
                    this.issueImagePreview = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        clearImageFile() {
            this.issueImagePreview = "",
                this.issueAttachmentName = ''
            this.issueAttachmentBlob = null
            this.issueAttachmentType = ''
        },
        async handleCategorySelection() {
            try {
                this.formData.childCategories = {}
                const response = await QueryFormController.getIssueCategory(this.formData.issueCategory.id);
                this.childCategories = response.data.data?.childCategories;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        onEditorReady(editor) {
            setTimeout(() => {
                const wiris_license_overlays = document.querySelectorAll('.cke_notification_warning')
                wiris_license_overlays.forEach(element => {
                    element.remove();
                });
            }, 1000)
        }
    }
}
</script>
<style scoped>
.dotted-border {
    border: 2px dashed var(--gray-04);
    padding: 20px;
    border-radius: 16px;
}
</style>