<template>
    <main name="userNavMenu">
        <v-menu offset-y attach>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on" text icon>
                    <v-avatar>
                        <v-img src="../assets/user.png"></v-img>
                    </v-avatar>
                </v-btn>
            </template>
            <v-list>
                <v-list-item>
                    <span class="cursor" @click="openQueryFormModal">
                        Raise a Query
                    </span>
                </v-list-item>
                <v-list-item>
                    <span class="cursor" @click="handleContactDialog">
                        Contact Us
                    </span>
                </v-list-item>
                <v-list-item v-if="userInfo?.userType != 'NTP'">
                    <span class="cursor" @click="logout">
                        Log Out
                    </span>
                </v-list-item>

            </v-list>
        </v-menu>
        <v-dialog v-model="isQueryFormModalOpen" max-width="1000px"  content-class="myScroll">
            <!-- <template v-slot:activator="{ on }">
                <v-btn v-on="on">Open-Query-Form</v-btn>
            </template> -->
            <QueryFormView @form-submitted="handleFormSubmission" @form-closed="handleFormClose"
                :email="$store.getters.userInfo.personalInfo.contactInfo.email"
                :phoneNumber="$store.getters.userInfo.personalInfo.contactInfo.phoneNumber"
                :firstName="$store.getters.userInfo.personalInfo.firstName"
                :lastName="$store.getters.userInfo.personalInfo.lastName" :userId="$store.getters.userInfo.userId" />
        </v-dialog>
     
            <v-dialog v-model="contactDialog" max-width="539px" >

            <v-card class="position-relative">
                <v-img @click="contactDialog = false" class="position-absolute icon-align" height="24px" width="24px" src="../assets/close-icon.svg"></v-img>
                <v-card-title>
                    <div class="d-flex justify-space-between">
                        <span>Contact Us</span>
                    </div>
                </v-card-title>
                <v-card-subtitle class="pt-2">
                    Have questions or need assistance? We're here to help! For any inquiries support, or issue, feel free to reach out to us.
                </v-card-subtitle>
                
                <div class="d-flex pa-6 justify-space-between">
                <div class="d-flex flex-column expand align-center  ">
                    <div class="img-container">
                        <v-img height="100px" width="100px" src="../assets/contact-us-call.svg"></v-img>
                    </div>
                        <p class="py-2">Phone</p>
                        <p class="link-color text-caption" @click="copyToClipboard('+91 9876543212', 'Contact number')">08065721000
                        </p>

                    </div>
                    <v-divider
                    vertical
                    ></v-divider>
                    <div class="d-flex flex-column expand align-center ">
                        <div  class="img-container">
                            <v-img height="100px" width="100px" src="../assets/contact-us-email.svg"></v-img>
                        </div>
                    <p class="py-2">Email</p>
                    <p class="link-color ml-2 text-caption">  <a class="link-color" v-if="userInfo?.userType === 'NTP'" :href="'mailto:' + IITED_SUPPORT_EMAIL">{{ IITED_SUPPORT_EMAIL }}</a>
                        <a class="link-color" v-else href="mailto:hubblestar.support@hubblehox.com">hubblestar.support@hubblehox.com</a> </p>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <v-snackbar
        z-index="500"
        v-model='showSnackbar'
        timeout="1000"
        >
          {{ copiedItem }}  copied

        </v-snackbar>
        <AlertDialogView text="Your query is submitted" v-model="openAlertDialog"
            @dialog-closed="openAlertDialog = false" />
    </main>
</template>

<script>
import QueryFormView from "../views/QueryFormView.vue";
import AlertDialogView from "./AlertDialog.vue";
import AuthService from '../services/AuthService';
import keycloakService from "../services/KeycloakService";

export default {
    name: 'UserNavMenu',
    components: {
        QueryFormView,
        AlertDialogView
    },
    data: () => {
        return {
            isQueryFormModalOpen: false,
            openAlertDialog: false,
            contactDialog:false,
            showSnackbar:false,
            copiedItem: '',
            isMobile: window.innerWidth <= 768,
            IITED_SUPPORT_EMAIL: 'contact@iited.com'
        }
    },
    // mounted() {
    //     this.userInfo = this.$store.getters.userInfo
    //     console.log('this.userInfo',this.userInfo.userType)
    // },
    created() {
        this.userInfo = this.$store.getters.userInfo
        console.log('this.userInfo',this.userInfo.userType)
    },
    methods: {
        openQueryFormModal() {
            this.isQueryFormModalOpen = true;
        },
        async copyToClipboard(text, item) {
            this.copiedItem = item
            if(this.isMobile){

                window.location.href = `tel:${text}`;
            }else{
                try {
                await navigator.clipboard.writeText(text);
                this.showSnackbar = true
                } catch (err) {
                    console.error('Failed to copy text: ', err);
                }
            }
        },
        logout() {
            AuthService.logout();
            this.$mixpanel.track("UserLoggedOut", {
                session_timeout: false,
                screen_name: "ThankyouScreen",
            });
            if (this.userInfo.userType === 'Teacher') {
                keycloakService.doLogout();
            } else {
                this.$router.push("/login");
            }
            this.$mixpanel.reset();

        },
        handleContactDialog(){
            this.contactDialog = !this.contactDialog
        },
        handleFormSubmission(formData) {
            console.log('Form submitted:', formData);
            this.openAlertDialog = true
            this.isQueryFormModalOpen = false; // Close the modal after submission
        },
        handleFormClose() {
            this.isQueryFormModalOpen = false;
        }
    }
}
</script>

<style scoped>
.cursor {
    cursor: pointer;
}

.expand{
    flex:1
}

.link-color{
   color: #3F41D1;
   text-decoration: underline;
}
.position-relative{
    position: relative;
}
.position-absolute{
    position: absolute;
 
}
.icon-align{
    top:15px;
    right: 15px;
}
.img-container{
    width: 100px;
}

</style>