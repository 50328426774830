<template>
    <v-dialog 
      :width="dialogWidth" 
      height="auto" 
      persistent 
      v-model="showLoader"
    >
      <v-card width="100%">
        <div class="loader-wrapper">
          <!-- Animation container -->
          <div class="animation-container">
            <slot name="loading-animation">
              <div class="bubble-loader">
                <div class="bubble"></div>
                <div class="bubble"></div>
                <div class="bubble"></div>
              </div>
            </slot>
          </div>
  
          <!-- Text container, only rendered if slot has content -->
          <div 
            v-if="hasLoadingText" 
            class="text-container"
          >
            <slot 
              name="loading-text"
              @slotchange="checkLoadingText"
            >
            </slot>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "bubble-loader",
    data() {
      return {
        showLoader: true,
        hasLoadingText: false,
        dialogWidth: '202px'
      }
    },
    mounted() {
      this.checkLoadingText()
    },
    methods: {
      checkLoadingText() {
        // Check if the loading-text slot has content
        const loadingTextSlot = this.$slots['loading-text']
        this.hasLoadingText = loadingTextSlot && loadingTextSlot.length > 0
        
        this.dialogWidth = this.hasLoadingText ? '330px' : '202px'
      }
    },
    updated() {
      // Recheck when component updates
      this.checkLoadingText()
    }
  };
  </script>
  
  <style scoped>
  .loader-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
  }
  
  .animation-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    min-height: 149px;
  }
  
  .text-container {
    text-align: center;
    margin-top: auto;
    padding-bottom: 20px;
  }
  
  .bubble-loader {
    position: relative;
    width: 60px;
    height: 60px;
    margin-top: 40px;
  }
  
  .bubble {
    width: 15px;
    height: 15px;
    background-color: #3F41D1;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: orbit 1.4s infinite ease-in-out;
    z-index: 9;
  }
  
  .bubble:nth-child(1) {
    animation-delay: -0.32s;
  }
  
  .bubble:nth-child(2) {
    animation-delay: -0.16s;
  }
  
  .bubble:nth-child(3) {
    animation-delay: 0s;
  }
  
  @keyframes orbit {
    0% {
      transform: rotate(0deg) translate(30px) rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg) translate(30px) rotate(-360deg);
    }
  }
  </style>