<template>
  <v-app>
    <v-main class="surface main-app-container" id="desktop">
      <router-view/>
    </v-main>
    <div id="phone">   <v-card  width="100%" height="100vh" class="d-none flex-column justify-center align-center">
            <v-card-title class="ma-4 text-center word-break">
              Assessment taking on your mobile device is supported via mobile app. Kindly request the admin to send you the app installation invitation.</v-card-title> 
<!-- <a href="https://ssvgos.page.link/home">
          <v-btn class="blue ma-4" color="primarydty"
          
          
          x-large rounded  >
            Get App 
          </v-btn>
        </a> -->
    </v-card>
  </div>
 
  </v-app>
</template>

<script>
import "./styles.css";
import AuthService from "./services/AuthService";
import UserController from "@/controllers/UserController";
import sessionTimeoutMixin from "./mixins/sessionTimeoutMixin";
/* global faceapi */

export default {
  name: 'App',

  data: () => ({
  }),
  methods: {
    async getUserInfo() {
      const response = await UserController.getUserInfo();
      //console.log(response);
      if (!response || response.status == 401) {
        AuthService.logout();
      } else {
        this.$store.dispatch('setUserInfo', response.data.data)
      }
    },
    async loadModelProgressively(modelList) {
      for (const modelName of modelList) {
        try {
          await faceapi.nets[modelName].loadFromUri('/models');
          console.log(`Loaded ${modelName} successfully`);
        } catch (error) {
          console.error(`Failed to load ${modelName}:`, error);
          // Handle error (e.g., try CPU, inform user)
          faceapi.tf.setBackend('cpu');
          // Attempt to reload this model using CPU
          await faceapi.nets[modelName].loadFromUri('/models');
        }
        // Add a small delay between loads to prevent overwhelming the GPU
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      this.$store.dispatch('setProctoringModelsLoaded', true);
    }

  },
   created() {
    if( AuthService.getUserId() ) {
      this.getUserInfo();
      const poller = AuthService.startPolling();
      this.$store.dispatch("setPollerId", poller)
    }
    const modelList = ['faceRecognitionNet', 'faceLandmark68Net', 'tinyFaceDetector'];
    this.loadModelProgressively(modelList);
  },
  mixins: [sessionTimeoutMixin],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.path !== '/dynamic-assessment') {
        vm.setupTimeout();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    AuthService.stopPolling();
    this.clearTimeout();
    next();
  }
};
</script>
