import axios from 'axios';
import AuthService from '@/services/AuthService';
import createAxiosInstance from '../interceptor/axiosInterceptor';
const instance = createAxiosInstance(process.env.VUE_APP_MASTERS_API_URL);

const umsInstance = createAxiosInstance(process.env.VUE_APP_USERS_API_URL);


export default {

    // Read an issue category by ID
    getIssueCategory: async function (categoryId) {
        try {
            const response = await instance.get(`/queryFormIssueCategory/${categoryId}`, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    getIssueCategories: async function () {
        try {
            const response = await instance.get(`/queryFormIssueCategories`, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    submitQuery: async function (queryData) {
        try {
            const response = await umsInstance.post(`/submitQueryForm`, queryData, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            });
            return response;
        } catch (error) {
            return error.response;
        }
    }
}