<template>
    <div class="w-100 date-breached-empty-screen">
        <v-app-bar app elevation="0" color="surface" class="justify-start py-3">
            <v-list-item class="d-flex justify-space-between webapp-main-topbar">
                <div class="flex-grow-1">
                <div class="logo-container">
                    <LogoComponent />
                </div>
                </div>
                <v-list-item-action class="mx-0 my-0">
                <div class="d-flex align-center user-profile-container">
                    <span class="help-icon" ><v-icon @click="$router.push('/faq')">mdi-help-circle-outline</v-icon></span>
                    <p role-type="subtitle-3" class="px-0 py-0 mb-0 webapp-text">Hello,</p>

                    <p role-type="subtitle-3" class="px-0 py-0 mb-0 ml-1 webapp-username" v-if="userInfo?.personalInfo != null"
                    >{{ userInfo?.personalInfo.firstName }} 👋
                    </p>
                    <UserNavMenu></UserNavMenu>
                </div>
                </v-list-item-action>
            </v-list-item>
        </v-app-bar>

        <div class="d-flex justify-center flex-column align-center pa-4" style="height: calc(100vh - 80px);">
            <div>
                <img
                src="../assets/LoginError.svg"
                alt="User is Archived"
            />
            </div>
            <div class="text-container mt-12 text-center">
                <p class="font-weight-bold text-center text-h6" >{{ restrictInfo.message }}</p>
                <p>Your account has been archived. You no longer have access to this link..</p>
            </div>  
        </div>
    </div>
</template>
<script>
import AuthService from '../services/AuthService';
import LogoComponent from '@/components/LogoComponent.vue';
import UserNavMenu from '../components/UserNavMenu.vue';

export default {
    name: 'DateBreached',
    components: {
        LogoComponent,
        UserNavMenu
    },
    data(){
        return{
            restrictInfo: {},
            userInfo: null
        }
    },
    mounted() {
        this.restrictInfo = this.$route.query.restrictInfo
        this.userInfo = this.$store.getters.userInfo
    },
    methods: {
        logout() {
            AuthService.logout();
            this.$mixpanel.track("UserLoggedOut", {
                session_timeout: false,
                screen_name: "ThankyouScreen",
            });
            this.$mixpanel.reset();
            this.$router.push("/login");
        }
    }
}
</script>
<style></style>