// AuthService.js

import axios from "axios";
import jwtDecode from "jwt-decode";
import AuthService from '../services/AuthService'
import store from '../store';
import keycloakService from "../services/KeycloakService";
import createAxiosInstance from "../interceptor/axiosInterceptor";

const TOKEN_KEY = "jwt";
const instance = createAxiosInstance(process.env.VUE_APP_AUTH_API_URL);

// const instance = axios.create({
//   baseURL: process.env.VUE_APP_AUTH_API_URL,
// })



export default {
  generateOTP: async function (data) {
    try {
      console.log("generating otp", data);
      const response = await instance.post("generate-otp", data);
      if (response.status == 200) {
        return response.data.flag;
      }
    } catch (error) {
      if (error.response.status === 404) {
        alert(error.response.data.error);
        return error.response.success;
        // response.data.error
      } else {
        // handle other errors
        alert(error.response.data.error);

        //console.log('An error occurred:', error.message)
        return error.response.success;
      }
    }
  },

  isAuthenticationExist: async function (phoneNumber) {
    try {
      const response = await instance.post(`authenticationExist?accessId=${AuthService.getUserId()}`, {
        phoneNumber,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  isAuthenticationExistEmail: async function (email) {
    try {
      const response = await instance.post(`authenticationExist?accessId=${AuthService.getUserId()}`, { email });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  //only for registration form
  // async verifyOTP(data) {
  //   try {
  //     console.log("validate data", data)
  //     const response = await instance.post('validate-otp', data);
  //     console.log("varified response",response)

  //     if (response.data.success) {

  //       return response.data;
  //     }
  //   }
  //   catch (error) {
  //     alert(error.response.data.error)
  //   }
  // },

  async validateOTP(data) {
    try {
      console.log("validate data", data);
      const response = await instance.post("validate-otp", data);
      console.log("validate response", response);
      if (response.status == 200) {
        const { token, refreshToken } = response.data;
        store.dispatch('setUserId', response.data.userId)
        store.dispatch('setToken', token);
        store.dispatch('setRefreshToken', refreshToken);
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log("validate otp error", error)
      alert(error.response.data.result);
    }
  },
  async verifySecondaryContact(data) {
    try {
      console.log("validate data", data,);
      const response = await instance.post(`verifySecondaryContact?accessId=${AuthService.getUserId()}`, data);
      console.log("validate response", response);
      if (response.status == 200) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  },

  logout: function () {
    store.dispatch('clearAllStates');
  },
  getUser: function () {
    const token = this.getToken();
    if (token) {
      const decoded = jwtDecode(token);
      return decoded.user ?? decoded.username;
    }
    return null;
  },
  getToken: function () {
    return store.getters.token;
  },
  async verifyRefreshToken (currentRefreshToken) {
    try {
      return await instance.post('/verify-refresh-token', { currentRefreshToken });
    } catch (error) {
      console.error('Error while generating refresh token', error);
    }
  },
  getUserId: function () {
    return store.getters.userId;

  },
  isAuthenticated: function () {
    const token = this.getToken();
    return store.getters.isAuthenticated && !this.isTokenExpired(token);
  },
  isTokenExpired: function (token) {
    const decoded = jwtDecode(token);
    const expirationDate = new Date(decoded.exp * 1000);
    return expirationDate < new Date();
  },
  async validateKeycloakToken(code,state) {
    console.log('validate Keyclock', code)
    console.log('validate Keyclock state', state)
    try {
      const key = 'kc-callback-' + state;
      
      const codeVerifierString = localStorage.getItem(key);

      const codeVerifier = JSON.parse(codeVerifierString);
      const pkceCodeVerifier = codeVerifier.pkceCodeVerifier;
      const authorizationCode = code; 

      const params = new URLSearchParams({
        code: authorizationCode,
        code_verifier: pkceCodeVerifier
      });
      
      const response = await instance.get(`/keycloakAuth/validateToken?${params.toString()}`);
      console.log("validate response", response);
      if (response.status == 200) {
        if(response.data.flag){
          console.log('in response flag validate')
            const { token, refreshToken } = response.data;
            store.dispatch('setUserId', response.data.userId);
            store.dispatch('setToken', token);
            store.dispatch('setRefreshToken', refreshToken);
            return response.data;
        }else{
          console.log('in response flag else validate')
           return response.data;
        }
        
      } else {
        console.log('response data',response.data)
        return response.data;
      }
    } catch (error) {
      console.log("validate otp error", error)
    }
  },
  startPolling() {
    function wait(ms) {
      console.log("waitt")
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    let poller; // Store requestAnimationFrame ID
    console.log("Start polling")
    const poll = async () => {  
      if (this.isTokenExpired(store.getters.token)) {
        try {
          const response = await this.verifyRefreshToken(store.getters.refreshToken);
          const { accessToken } = response.data;
          store.dispatch("setToken", accessToken);
        } catch (error) {
          console.error("Failed to refresh token", error);
        }
      }
  
      // Recursively call itself
      await wait(30000)
      poller = requestAnimationFrame(poll);
    };
  
    poller = requestAnimationFrame(poll); // Start polling
    return poller; // Store for later cancellation
  },
  
  stopPolling() {
    const pollerId = store.getters.pollerId
    if (pollerId) {
      cancelAnimationFrame(pollerId);
      store.dispatch("clearPollerId", pollerId);
    }
  }
  
  
};
