// sessionTimeoutMixin.js

import AuthService from "../services/AuthService";

const sessionTimeoutMixin = {
    data() {
        return {
            timeout: null,
            idleTimer: null,
            lastActivityTime: null,
        };
    },
    created() {
        this.setupIdleTracking();
        this.setupTimeout();
    },
    beforeDestroy() {
        this.clearTimeout();
        this.removeIdleTracking();
    },
    methods: {
        setupIdleTracking() {
            // Events that indicate user activity
            this.activityEvents = [
                'mousedown', 
                'mousemove', 
                'keydown', 
                'scroll', 
                'touchstart'
            ];

            // Bind the reset method to ensure correct context
            this.resetActivityTime = this.resetActivityTime.bind(this);

            // Add event listeners
            this.activityEvents.forEach(event => {
                window.addEventListener(event, this.resetActivityTime);
            });

            // Initialize last activity time
            this.lastActivityTime = Date.now();
        },
        removeIdleTracking() {
            // Remove event listeners
            this.activityEvents.forEach(event => {
                window.removeEventListener(event, this.resetActivityTime);
            });
        },
        resetActivityTime() {
            this.lastActivityTime = Date.now();
            this.clearTimeout();
        },
        setupTimeout() {
            // Idle timeout duration (20 minutes)
            const IDLE_TIMEOUT = 20 * 60 * 1000;

            this.$router.beforeEach((to, from, next) => {
                if (AuthService.isAuthenticated()) {
                    // Check route conditions
                    if (to.path !== '/dynamic-assessment' && to.name !== 'GenericAssessmentView') {
                        // Start idle timeout check
                        this.idleTimer = setInterval(() => {
                            const currentTime = Date.now();
                            const idleTime = currentTime - this.lastActivityTime;

                            if (idleTime >= IDLE_TIMEOUT) {
                                // Clear interval to prevent multiple popups
                                clearInterval(this.idleTimer);

                                // Show popup
                                if (confirm('Your session will expire soon due to inactivity. Click OK to stay logged in.')) {
                                    // User clicked OK, reset the activity time
                                    this.resetActivityTime();
                                    // Restart idle tracking
                                    this.setupTimeout();
                                } else {
                                    // User didn't respond or clicked Cancel, logout
                                    this.logout();
                                }
                            }
                        }, 60000); // Check every minute
                    } else {
                        this.clearTimeout();
                    }
                }
                next();
            });
        },
        clearTimeout() {
            // Clear both interval and timeout
            if (this.idleTimer) {
                clearInterval(this.idleTimer);
                this.idleTimer = null;
            }
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
        },
        logout() {
            // Remove idle tracking before logout
            this.removeIdleTracking();

            AuthService.logout();
            this.$mixpanel.track("UserLoggedOut", {
                session_timeout: true,
                screen_name: "ThankyouScreen",
            });
            this.$mixpanel.reset();
            this.$router.push("/login");
        },
    },
};

export default sessionTimeoutMixin;